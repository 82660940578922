<!--此模块为后端人员参照智能问答页面所写。。。如有问题，还请见谅-->
<template>
  <div class="chat-content">
    <dl class="rebot-style" v-if="!historyPage">
      <dt>
        <img :src="require(`@/assets/images/chat_image_head_bebot.png`)">
      </dt>
      <dd style="min-width: 5rem;">
        
        <div class="dialog-content">
          <p class="tip-text" v-text="welcome_msg"></p>
          <!-- 有常见问题和办事导航信息 -->
          <div v-if="defaultDialog==1">
            <div class="tip-container">
              <span 
                class="dept-tag-text"
                v-for="(item,index) in ['常见问题', '新人办事导航']"
                :key="index"
                @click="getTagOrQuestion(item)"
              >
                  {{item}}
              </span>
            </div>
          </div>
          <!-- 只有常见问题 -->
          <div v-if="defaultDialog==2">
            <div class="tip-container">
              <span
                class="dept-tag-text"
                v-for="(item,index) in gategoryList"
                :key="index"
                @click="getQuestion(item)"
              >
                  {{item}}
              </span>
            </div>
            <p
              v-if="gategoryPage>0"
              @click="changeGategory"
              class="cutover"
            >换一换</p>
          </div>
          <!-- 只有办事导航 -->
          <div v-if="defaultDialog==3">
            <div class="tip-container">
              <span
                class="dept-tag-text"
                v-for="(item,index) in workGategory"
                :key="index"
                @click="getWorkQuestion(item)"
              >
                  {{item}}
              </span>
            </div>
            <p
              v-if="workPage > 0"
              @click="changeWork"
              class="cutover"
            >换一换</p>
          </div>
        </div>
      </dd> 
    </dl>
    <div
      v-for="(item, index) in list"
      :key="item.id"
    >
      <dl>
        <dd>
          <!-- item.mode 0普通，1智能核保 &&item.mode!=1 -->
          <!-- item.content && item.content.hebao == 0 zzj添加 -->
          <p
            class="add-chat"
            v-if="item.content && item.content.hebao == 0 && item.speaker==0&&item.is_collected=='0' &&
            underwriting!=1"
            @click="addChatToKnowdge(index)"
          >
            <img :src="require('@/assets/images/common_icon_add.png')" />
            添加对话至收藏夹
          </p>
          <p
            class="go-look"
            v-if="item.speaker==0&&item.is_collected==1"
            @click="goLook(item,index)"
          >
            前往查看
          </p>
        </dd>
      </dl>
      <dl
        v-if="underwriting == 1 && item.content!='核保' || underwriting!=1"
        :class="item.speaker == 0 ? 'rebot-style' : 'custom-style'"
      >
        <dt>
          <img :src="item.speaker==0?require(`@/assets/images/chat_image_head_bebot.png`):userInfo.head_portrait">
        </dt>
        <dd>
          <div :class="item.underwriting==2&&item.content.type=='nameCard'?'name-card-container':'content'">
            <!-- 左侧回答 -->
            <span
              v-if="item.speaker == 1"
              class="contentText"
              v-html="item.content"
              style="word-break:break-all;"
            ></span>
            <!-- 机器人普通对话 -->
            <div
              v-if="item.speaker == 0 && item.underwriting == 2 && item.content.type == 'answer'"
              class="contentText"
            >
              <p
                :style="index+1==list.length?'padding-bottom:.27rem':'padding-bottom:0'"
                :class="each.canClick?'chatText':'noChatText'"
                v-for="(each,num) in item.content.answer"
                :key="num"
              >
                <span
                  :style="each.canClick?'text-decoration: underline;':''"
                  v-show="each"
                  @click="getAnswer(each.text, each.canClick)"
                  v-html="$options.filters.replaceLabel(each.text)"
                >
                </span>
              </p>
            </div>
            
            <!-- 分类列表 -->
            <div v-if='item.speaker == 0 && item.underwriting == 2 && item.content.type == "classifyList"'>
              <p class="tip-text">请选择您想咨询的常见问题类型，或直接输入想问的问题，我可快速为您解答哦~</p>
              <div class="tip-container">
                <span 
                  class="dept-tag-text"
                  v-for="(item,num) in historyPage?item.content.gategoryList:gategoryList"
                  :key="num"
                  @click="getQuestion(item)"
                >
                    {{item}}
                </span>
              </div>
              <p
                v-if="gategoryPage>0"
                @click="changeGategory"
                class="cutover"
              >换一换</p>
            </div>
            
            <!-- 办事导航分类列表 -->
            <div v-if='item.speaker == 0 && item.underwriting == 2 && item.content.type == "NaviClassifyList"'>
              <p class="tip-text">请选择您想咨询的新人办事导航类型，或直接输入想问的问题，我可快速为您找到相关负责人哦~</p>
              <div class="tip-container">
                <span
                  class="dept-tag-text"
                  v-for="(item,num) in historyPage?item.content.gategoryList:workGategory"
                  :key="num"
                  @click="getWorkQuestion(item)"
                >
                    {{item}}
                </span>
              </div>
              <p
                v-if="workPage>0"
                @click="changeWork"
                class="cutover"
              >换一换</p>
            </div>
            <!-- 问题列表 -->
            <div
              v-if='item.speaker == 0 && item.underwriting == 2 && item.content.type == "questionList"'
            >
              <p class="tip-text" v-if="item.content.questionList.length > 0">您是否想咨询以下问题，点击以下问题，我可以回答您详细内容哦～</p>
              <p class="tip-text" v-else>唔~您要找的问题不见了哦！换换其他类型吧~</p>
              <!-- 换一换问题列表,历史对话也不显示换一换 -->
              <div v-if="index+1==list.length&&!historyPage">
                <p
                  :style="index+1==list.length?'padding-bottom:.27rem':'padding-bottom:0'"
                  class="chatText"
                  v-for="(text,num) in showTmpQues"
                  :key="num"
                >
                  <span
                    class="question-text"
                    style="text-decoration: underline;"
                    v-show="text"
                    @click="getAnswer(text)"
                    v-text="text"
                  >
                  </span>
                </p>
                <p
                  v-if="tmpQuestionList.length>3"
                  @click="changeQuestion"
                  class="cutover"
                >换一换</p>
              </div>

              <!-- 问题列表 -->
              <p v-else
                :style="index+1==list.length?'padding-bottom:.27rem':'padding-bottom:0'"
                class="chatText"
                v-for="(text,num) in item.content.questionList"
                :key="num"
              >
                <span
                  class="question-text"
                  style="text-decoration: underline;"
                  v-show="text"
                  @click="getAnswer(text)"
                  v-text="text"
                >
                </span>
              </p>
            </div>
            
            <!-- 代理人名片 -->
            <div
              v-if='item.speaker == 0 && item.underwriting == 2 && item.content.type == "nameCard"'
            >
              <!-- 显示手机号和拨打电话 -->
              <div v-if="showCardMobile">
                <div class="broker-img-tel">
                  <img class="broker-img" :src="item.content.profilePig?item.content.profilePig:'https://yxyy-data.oss-cn-beijing.aliyuncs.com/08_bebotHead/head_portrait.png'" 
                    onerror="this.src='https://yxyy-data.oss-cn-beijing.aliyuncs.com/08_bebotHead/head_portrait.png'"
                  />
                  <a class="broker-tel" :href="'tel:'+ item.content.mobile">
                    <div>
                    <img class="tel-icon" :src="require(`@/assets/images/tel-icon.png`)" alt=""><span>打电话</span>
                    </div>
                  </a>
                </div>
                <div class="broker-info">
                  <div class="broker-info-left">
                    <div>
                      <span class="broker-name" v-text="item.content.people"></span><span style="color: #666666"> 负责人</span>
                    </div>
                    <div class="broker-adderss">综合岗位：<span  v-text="item.content.address"></span></div>
                    <div>电话：<span  v-text="item.content.mobile"></span></div>
                  </div>
                  <div class="qr-code-con" v-if="item.content.wechatUrl">
                    <div class="qr-text">微信二维码</div>
                    <img class="qr-code" :src="item.content.wechatUrl" alt="">
                  </div>
                  <div class="clear"></div>
                  <div v-if="item.content.peopleFrofile">
                    <div class="broker-hr"></div>
                    <div class="broker-intro" v-text="item.content.peopleFrofile"></div>
                  </div>
                  <div v-else style="height:0.93rem"></div>
                </div>
              </div>
              <!-- 不显示手机号 -->
              <div v-else>
                <div class="text-center">
                  <img class="broker-img" :src="item.content.profilePig?item.content.profilePig:'https://yxyy-data.oss-cn-beijing.aliyuncs.com/08_bebotHead/head_portrait.png'" 
                      onerror="this.src='https://yxyy-data.oss-cn-beijing.aliyuncs.com/08_bebotHead/head_portrait.png'"
                    />
                  <div class="broker-info">
                    <span class="broker-name" v-text="item.content.people"></span><span style="color: #666666"> 负责人</span>
                    <div class="address-center" >综合岗位：<span  v-text="item.content.address"></span></div>
                  </div>
                </div>
                <div class="broker-hr"></div>
                <div>
                  <div :class="['broker-intro', item.content.wechatUrl ? 'intro-left' : '',]" style="margin-bottom: 0;" v-text="item.content.peopleFrofile?item.content.peopleFrofile:'“用我们的平台，让保险更简单，造就更多人的幸福”'"></div>
                  <div class="qr-code-con" v-if="checkImg(item)&&item.content.wechatUrl">
                    <img class="qr-code-small" :src="item.content.wechatUrl" alt="">
                    <div class="qr-text qr-text-small">微信二维码</div>
                  </div>
                  <div class="clear"></div>
                </div>
              </div>
            </div>
          </div>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import { companyDepartment, deptQuestion, getShowMobile } from "@/fetch/api/chat.js";
import { mapState, mapMutations } from "vuex";
// import TipBar from "./TipBar.vue";
export default {
  name:"questionContent",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      text: "",
      defaultDialog:0,
      historyPage:false,
      underwriting: this.$route.query.underwriting,
      hideHistoryText: this.$route.query.hideHistoryText,
      welcome_msg: "请选择您想咨询的问题类型，或直接输入想问的问题，我可快速为您解答哦~",
      showCardMobile: false,
      userParam: {"userId": localStorage.getItem("jyb_id")},
      gategoryList: [],
      quesGategory: [],
      gategoryPage:0,
      questionPage:0,
      workPage: 0,
      tmpQuestionList:[],
      showTmpQues:[],
      // 
      allWorkGategory:[],
      workGategory: [],
      selectedDept:"",
      selectedGory:"",
      jyb_id: localStorage.getItem("jyb_id")
    };
  },
  directives: {
    numberOnly: {
      bind: function (el) {
        el.handler = function () {
          el.value = el.value.replace(/\D+/, "");
        };
        el.addEventListener("input", el.handler);
      },
      unbind: function (el) {
        el.removeEventListener("input", el.handler);
      },
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  filters: {
    replaceLabel: function (val) {
      if (val && val.indexOf("<br>") > -1) {
        return val.replace(/<br>/g, "\n");
      } else {
        return val;
      }
    },
  },
  components: {
    // TipBar,
  },
  mounted() {
    if(location.href.indexOf('/chat/history')!=-1){
      this.historyPage = true
    }else{
      this.getDefaultDialog()
      this.getDept();
    }
    getShowMobile().then((res)=>{
      if(res.code==200){
        if (res.show_mobile == 1){
          this.showCardMobile = true
        }
      }
    })
  },
  methods: {
    ...mapMutations(["SET_QUESTION_CHAT_LIST"]),
    getDefaultDialog(){
      deptQuestion(this.userParam).then((res) => {
        if (res.code == 10000){
          if (res.data.navigationPeopleRespList.length > 0 && res.data.shouldList.length > 0){
            this.defaultDialog = 1
          }else if(res.data.navigationPeopleRespList.length == 0 && res.data.shouldList.length > 0){
            this.defaultDialog = 2
            this.welcome_msg = "请选择您想咨询的常见问题类型，或直接输入想问的问题，我可快速为您解答哦~"
          }else if(res.data.navigationPeopleRespList.length > 0 && res.data.shouldList.length == 0){
            this.defaultDialog = 3
            this.welcome_msg = "请选择您想咨询的新人办事导航类型，或直接输入想问的问题，我可快速为您找到相关负责人哦~"
          }
          // 获取办事导航的分类
          this.allWorkGategory = []
          for (let i=0; i<res.data.navigationPeopleRespList.length;i++){
            this.allWorkGategory = this.allWorkGategory.concat(res.data.navigationPeopleRespList[i]['classify'])
          }
          this.allWorkGategory = Array.from(new Set(this.allWorkGategory))
          if (this.allWorkGategory.length>9){
            this.workPage = 1
            this.changeWork()
          }else{
            this.workGategory = this.allWorkGategory
          }
        }
      })
    },
    async getDept(sendClassify) {
      const response = await companyDepartment(this.jyb_id);
      if (response.code == 10000) {
        //判断显示默认对话框
        this.quesGategory = []
        for (let i=0; i<response.data.length;i++){
          this.quesGategory = this.quesGategory.concat(response.data[i]['classifyList']) 
        }
        this.quesGategory = Array.from(new Set(this.quesGategory))
        if (this.quesGategory.length>9){
          this.gategoryPage = 1
          this.changeGategory()
        }else{
          this.gategoryList = this.quesGategory
        }
        if (sendClassify){  // 获取分类信息并发送消息
          let data = {"gategoryList": this.gategoryList, "type": "classifyList"}
          this.bebotSpeak(data)
        }
      } else {
        this.$toast("请求分类失败，请稍后重试");
      }
    },
    getTagOrQuestion(tag, workClassify, sendText=true){
      
      if (tag=="常见问题"){
        this.$emit("saveRecord", tag, 1, );
        this.getDept(true)// 重新获取分类信息并发送消息
      }else{  //办事导航
        //this.getQuestion(tag, "work")
        deptQuestion(this.userParam).then((res)=>{
          if(res.code==10000){
            if(workClassify){ //取单个办事导航分类下的问题
              if(sendText){this.$emit("saveRecord", workClassify, 1, );}
              let question_list = this.allWorkGategory = res.data.navigationPeopleRespList.filter((nv)=>{
                if (nv.classify == workClassify){
                  return nv
                }
              })
              question_list = question_list.map((q)=>{return q.program})
              this.tmpQuestionList = question_list
              this.showTmpQues = question_list.slice(0, 3)
              this.questionPage = 1
              let data = {"questionList": question_list, "type": "questionList"}
              this.bebotSpeak(data)
            }else{  //取办事导航分类
              this.$emit("saveRecord", tag, 1, );
              this.allWorkGategory = res.data.navigationPeopleRespList.map((nv)=>{
                return nv.classify
              })
              this.allWorkGategory = Array.from(new Set(this.allWorkGategory))
              if (this.allWorkGategory.length>9){
                this.workPage = 1
                this.changeWork()
              }else{
                this.workGategory = this.allWorkGategory
              }
              let data = {"gategoryList": this.workGategory, "type": "NaviClassifyList"}
              this.bebotSpeak(data)
            }
          }
        })
      }
    },
    getWorkQuestion(tag, sendText){
      this.getTagOrQuestion(false, tag, sendText)
    },
    bebotSpeak(data){
      setTimeout(()=>{
        this.$emit("saveRecord", data, 0);
      }, 200)
    },
    changeGategory(){
      let pageSize = 9
      let gateCount = this.quesGategory.length;
      let pageCount = Math.ceil(gateCount / pageSize)
      if (this.gategoryPage > pageCount){
        this.gategoryPage = 1
      }
      this.gategoryList = this.quesGategory.slice((this.gategoryPage-1)*pageSize, this.gategoryPage*pageSize)
      this.gategoryPage++
    },
    setTmpQuestion(quesList){
      this.questionPage=1
      this.tmpQuestionList = quesList
      this.showTmpQues = quesList.slice(0, 3)
    },
    changeQuestion(){
      let pageSize = 3
      this.questionPage++
      let quesCount = this.tmpQuestionList.length;
      let pageCount = Math.ceil(quesCount / pageSize)
      if (this.questionPage > pageCount){
        this.questionPage = 1
      }
      this.showTmpQues = this.tmpQuestionList.slice((this.questionPage-1)*pageSize, this.questionPage*pageSize)
    },
    changeWork(){
      let pageSize = 9
      let quesCount = this.allWorkGategory.length;
      let pageCount = Math.ceil(quesCount / pageSize)
      if (this.workPage > pageCount){
        this.workPage = 1
      }
      this.workGategory = this.allWorkGategory.slice((this.workPage-1)*pageSize, this.workPage*pageSize)
      this.workPage++
    },
    getQuestion(tag=null, type=null, sendText=true){
      if(sendText){
        this.$emit("saveRecord", tag, 1);
      }
      let param = {"userId": this.jyb_id}
      if (tag) {
        param["classify"]=tag
      }
      this.$emit("getQuestion", param, type);
    },
    
    getAnswer(item, click=true){
      if(click){
        this.$emit("saveRecord", item, 1, );
        this.$emit("getAllQuestion", false, item)
      }
    },
    checkGategory(text, sendText=true){
      if (this.quesGategory.includes(text)){  // 输入的信息在常见问题分类中
        this.getQuestion(text, null, sendText)
        return true
      }else if (this.allWorkGategory.includes(text)){ // 输入的信息在办事导航分类中
        this.getWorkQuestion(text, sendText)
        return true
      }
      return false
    },
    addChatToKnowdge(index) {
      let list = this.list;
      this.SET_QUESTION_CHAT_LIST({ type: "assignment", data: list });
      let collectList = [list[index - 1], list[index]];
      this.$router.push(`/knowledge-base/edit/0?type=collect`);
      sessionStorage.setItem("collectList", JSON.stringify(collectList));
    },
    goLook(item) {
      this.$router.push(`/knowledge-base/detail/${item.online_data_id}`);
    },
    checkImg(item){
      let imgUrl = item.content.wechatUrl
      return new Promise(function(resolve, reject) {
        var ImgObj = new Image(); //判断图片是否存在
        ImgObj.src = imgUrl;
        ImgObj.onload = function(res) {
            resolve(res);
        }
        ImgObj.onerror = function(err) {
            reject(err)
            item.content.wechatUrl=''
        }
      }).catch((e) => {console.log(e)});
    }
  },
};
</script>

<style lang="scss" scoped>
.text-center{text-align: center;}
.dialog-content{
  color: #ffffff;
  max-width: 6.9rem;
  background: rgba(122, 153, 250, 0.4);
  border-radius: 2px 20px 20px 20px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  padding: 0.21rem 0.3rem;
}
.tip-text{
  font-size: 0.37rem;
  font-family: PingFangSC-Regular, PingFang SC;
  margin-bottom: 0.26rem;
}
.tip-container{
  display: flex;
  flex-flow: wrap;
}
.cutover {
    text-align: right;
    margin: 0.5rem 0.2rem 0 0;
    font-size: 0.373rem;
  }
.dept-tag-text {
  margin: 0 4px 0.266rem 0;
  padding: 0.13rem 0.186rem;
  font-size: 0.373rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  border-radius: .13rem;
  background: rgba(24, 50, 155, 0.2);
}

.contentText {
  font-size: 0.37rem;
  position: relative;
  text-align: left;
  min-width: 5.33rem;
  overflow-wrap: break-word;
}

.noChatText {
  opacity: 0.9;
  padding-bottom: 0.24rem;
  white-space: pre-line;
}
.chatText {
  font-size: 0.37rem;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #ffffff;
  font-weight: 700;
  padding-bottom: 0.16rem;
}
.question-text{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; //行数
  overflow: hidden;
}

.name-card-container{
  padding: 0.42rem;
  margin-left: 0.13rem;
  width: 6.48rem;
  background: #FFFFFF;
  border-radius: 2px 20px 20px 20px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  position: relative;
  background-image: url("../../../assets/images/name_card_bg.png");
  background-repeat : no-repeat;
  background-position-y: bottom;
  background-size: 100%;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  .broker-img{
    width: 1.92rem;
    height: 1.92rem;
    border-radius: 50%;
  }
  .tel-icon{vertical-align: middle}
  .broker-tel{
    width: 2.24rem;
    height: 0.906rem;
    font-size: .373rem;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    line-height: 0.906rem;
    background: #476DD1;
    border-radius: 0.533rem;
    text-align: center;
    display: block;
    color: #ffffff;
    float: right;
    margin-top: 0.533rem;
  }
  .broker-info{margin-top: 0.2rem;}
  .broker-info-left{
    float: left;
  }
  .broker-adderss{
    margin: .48rem 0 0.186rem 0;
  }
  .address-center{margin-top: 0.213rem;}
  .broker-name{
    font-size: .58rem;
    font-weight: 600;
    color: #333333;
  }
  .qr-code-con{
    float: right;
    text-align: center;
  }
  .qr-text{
    font-size: 0.293rem;
    color: #333333;
    letter-spacing: 1px;
  }
  .qr-text-small{
    font-size: .29rem;
  }
  .qr-code{
    width: 2.02rem;
    height: 2.02rem;
  }
  .qr-code-small{
    width: 1.65rem;
    height: 1.65rem;
  }
  .broker-hr{
    height: 1px;
    background-color: #DFE2EB;
    opacity: 0.6;
    margin: 0.4rem 0;
  }
  .broker-intro{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; //行数
    overflow: hidden;
    margin-bottom: .64rem;
    font-size: 0.373rem;
    line-height: 0.586rem;
  }
  .intro-left{
    float: left;
    width: 3.78rem;
  }
}
.clear{clear: both;}
</style>
